.icons{
    height: 44px;
    width: 44px;
}

.Footer-icons{
    display: grid; 
    grid-template-columns: repeat(3,auto);
    grid-gap: 10%;
}

body{
    position: relative;
    min-height: 100vh;
}

.Footer-stage{
    display: flex;
    justify-content: center;
    margin-top: 25px;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 25px;
}

.Footer-title{
    margin: 20% 0;
} 
a{
    display: flex;
    flex-direction: column;
}

.github-link, .linkedin-link, .email-link{
    display: flex;
    justify-content: center;
    width: 50px;
    height: 62px;
}


.linkedin-lightMode{
    fill: #0e76a8;
}

.cls-2{
    fill: white;
    color:white;
}
.name-lightMode {
    color:black;
    display: flex;
    align-self: center;
 
}

.name-darkMode, .github-darkMode{
    color:white;
    fill:white;
    display: flex;
    align-self: center;
}
svg{
    font-size: -webkit-xxx-large;
}

.emailIcon-darkMode{
    color: white;
}
