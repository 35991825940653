ul{
  list-style-type: none;
  border:0px; 
}

.Navbar-stage{
  display: flex;
  top: 60px;
  margin-top: 0;
  flex-grow: 1;
  padding: 0px 20px 54px 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}


@media screen and (max-width: 665px) {
  .Navbar-stage{
    display: flex;
    top: 60px;
    margin-top: 0;
    flex-grow: 1;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    font-size: 2.5vw;
  }
}
.link-div{
  padding:2%;
  
}

.Nav-link-darkMode:link, .Nav-link-darkMode:visited, .Nav-link-darkMode:active, .Nav-link-darkMode, 
.Nav-link-lightMode:link, .Nav-link-lightMode:visited, .Nav-link-lightMode:active, .Nav-link-lightMode {

  text-decoration: none;
  color: white;
  font-size: 1em;

}

.Nav-link-lightMode:after, .Nav-link-darkMode:after {

  display: block;
  height: 1px;
  width: 0;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}

.Nav-link-darkMode:hover:after  {
  width: 100%;
  background:  #FF2247;
}

.Nav-link-lightMode:hover:after   {
  width: 100%;
  background:  #eda5a9;
}

.x-logo{
    display: flex;
}