.About-container{
    display: grid;
    grid-template-rows: 150px 250px;
    margin-left: 20%;
    margin-right: 20%;
    height: auto;
}


.About-card{
    transition: 0.3s;
}


.About-title{
    align-self: center;
    margin: 0px;
    padding: 15px;
}

.About-description{
    margin: 0px;
    align-self: center;
    padding: 15px;

    font-family: "Helvetica Neue";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
}

@media only screen and (267px <= width <= 326px)  {
    .About-title{
    padding: 0px;
    font-size: 23px;
    }
}

 
@media only screen and (max-width: 266px){ 
    .About-title{
        padding: 0px;
        font-size: 15px;
}
    .About-description{
        padding: 6px;
        font-size: 14px;
    }
}

@media only screen and (min-height: 281px){ 
    .About-container{
        align-content: normal;
    }

}




.About-image{
    height: 100%;
    text-align: -webkit-center;
}

.profile-photo{
    height: 150px;

}



@media screen and (max-width: 665px) {
    .About-container {
      margin-bottom: 75px;
    }
}

