.cards_item {
  width: 85%;
}

.project_image{
  display: block;
  width: 100%;
  height: 100%;
}

.card_btn {
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  width: 45%;
  display: inline-block;
  border: 1px solid white;
}

.card_btn:hover {
  background-color: white;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.cards_item {
  display: flex;
  padding: 1rem;
}




.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  padding: 1rem;
  background-color: lightgray;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.card_text {
  font-size: 1.4rem;
  position: absolute;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
}

.card {
position: relative;
max-height: 400px;
min-height: 225px;
}


.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: lightgray;
}

.card:hover .overlay {
  opacity: 1;
}


a.link , a:visited, a:active,a {
  text-decoration: none;
  color:black;
}

a:hover{
  color: #FFE3DA;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 45%;
  }
  .cards{
    justify-content: center;
    padding: 0px;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 30.33%;
  }
}
