 
.contact-form{
    margin: 0px 20%;
    display: flex;
    flex-direction: column;
}

input, textarea{
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin-bottom: 25px;
}

.message-label, .email-label, .name-label{
    padding:10px 0px 10px 3px;
    align-self: flex-start;
    width: 100%;
    margin: 10px 0px;
} 

.message{
    padding-bottom: 50px;
}

.contact-form-inputs{
    border:none;
}

.submit-message{
    grid-column-end: 3;
    justify-self: center;
    grid-area: 3 / 1 / 3 / 3;
}

.submit-button-lightMode,  .submit-button-darkMode{
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    grid-area: 3 / 2 / 3 / 3;
    width: 25%;
    align-self: flex-end;
    font-size: 1em;
}
.submit-button-lightMode{
    background-color: #cebabb;
}

.submit-button-darkMode{
    background-color: lightgray;
    border: 1px solid #80808030;
}

label{
    font-size: 1.5em;
}