.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 10px;
}
  
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
  
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgray;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid #80808030;
}
  
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
  
input:checked + .slider {
  background-color: #eda5a9;
}
  
input:focus + .slider {
  box-shadow: 0 0 1px #eda5a9;
}
  
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
  
.slider.round {
  border-radius: 34px;
}
  
.slider.round:before {
  border-radius: 50%;
}
  