.Light{
  color: black;
  min-height: 100vh;
  fill: black;
  background-color: #FFE3DA;
}

.Dark{
  background-color: #121212;
  color:white;
  min-height: 100vh;
  fill: white;
}

.buttonsContainer{
  text-align: right;
}

body{
  margin:0px;
  font-family: 'Playfair Display', serif;
}

.Home-stage, .Projects-stage, .About-stage,  .Contact-stage, .Skills-stage {
  height: 100%;
  padding-bottom: 5rem;
} 

.Nav-heading-lightMode{
  color: #eda5a9;
  margin: 0px;
}

.Nav-heading-darkMode{
  color: #121212;
  margin: 0px;
}